import jwt_decode     from "jwt-decode";
import AuthError      from "@/errors/AuthError";
import axios          from "axios";
import router         from '@/router'
import * as constants from '@/constants'


const storeKey = 'bulkemail.store.user';

const getDefaultState = () => {
	return {
		accessToken: '',
		refreshToken: '',
		roles: [],
		user: {},
		isSignedIn: false,
		fresh: false,
		working: false,
		gettingAccessToken:false
	}
}

const state = getDefaultState()

const getters = {

	isSignedIn: function ( state ) {
		if( !state.isSignedIn || !state.fresh ) {
			return false;
		}
		return true;
	},

	isWorking: function ( state ) {
		return state.working;
	},

	userId: function ( state ) {
		return state.user.userId;
	},

}

const actions = {
	initialize: async function ( {commit} ) {
		console.log( storeKey + ' initialize' )
		await commit( 'setWorking', true );
		await commit( 'setModuleStateFromLocalStorage' );
		await commit( 'setWorking', false );
	},

	reset: async function ( { commit } ) {
		console.log( storeKey + ' reset' )

		await commit( 'reset' );

		if( localStorage.getItem( storeKey ) ) {
			localStorage.removeItem( storeKey );
		}
	},

	getAccessToken: async function ( {
		state,
		commit,
		dispatch
	} ) {
		if(state.gettingAccessToken) {
			console.log( 'getAccessToken - waiting' );
			await constants.waitUntil( ()=>!state.gettingAccessToken )
		}
		commit('setGettingAccessToken', true)

		console.log( 'getAccessToken - start' );
		let getNewToken = false;

		try {
			let decodedCurrentAccessToken = jwt_decode( state.accessToken );
			if( Date.now() >= ( decodedCurrentAccessToken.exp * 1000 ) ) {
				getNewToken = true;
				console.log( 'getAccessToken - access token expired' );
			}
		}
		catch( e ) {
			console.log( 'getAccessToken - access token invalid' );
			getNewToken = true;
		}

		if( getNewToken ) {
			await commit( 'setAccessToken', '' );
			console.log( 'getAccessToken - exchange refresh token' );
			await dispatch( 'exchangeRefreshToken' );
		}

		await commit( 'setFresh', true );
		console.log( 'getAccessToken - return access token' );
		commit('setGettingAccessToken', false)
		return state.accessToken;
	},

	exchangeRefreshToken: async function ( {
		state,
		dispatch,
		commit
	} ) {
		console.log( 'exchangeRefreshToken - start' );

		//if refresh token is invalid or expired, send user to sign in page
		let refreshTokenExpiredInvalid = false;
		try {
			let decodedCurrentRefreshToken = jwt_decode( state.refreshToken );
			if( Date.now() > ( decodedCurrentRefreshToken.exp * 1000 ) ) {
				refreshTokenExpiredInvalid = true;
				console.log( 'refresh token expired' );
			}
		}
		catch( e ) {
			console.log( 'exchangeRefreshToken - refresh token invalid' );
			refreshTokenExpiredInvalid = true;
		}

		if( refreshTokenExpiredInvalid ) {
			console.log( 'exchangeRefreshToken - redirect user to sign in page' );
			await router.push( {name: 'auth.signIn'} )
			return;
		}


		//refresh token is valid, exchange it for a new access token and refresh token
		let payload = {
			'grant_type': 'refresh_token',
			'refresh_token': state.refreshToken,
			'client_id': process.env.VUE_APP_API_CLIENT_ID,
			'scope': state.roles.join( ' ' )
		};

		try {
			console.log( 'exchangeRefreshToken - exchange refresh token' );
			let apiResponse = await axios.post( process.env.VUE_APP_API_URL + 'auth/authorize', payload );
			commit( 'setDataFromToken', apiResponse.data.access_token );
			commit( 'setRefreshToken', apiResponse.data.refresh_token );
		}
		catch( e ) {
			dispatch('reset')
			console.log( 'exchangeRefreshToken - error exchanging refresh token: ' + e.message );
			await router.push( {name: 'auth.signIn'} )
		}

		console.log( 'exchangeRefreshToken - end' );
	},

	exchangeAuthorizationGrantCode: async function ( {
		commit
	}, authorizationGrantCode ) {
		console.log( 'exchangeAuthorizationGrantCode - start' );

		//refresh token is valid, exchange it for a new access token and refresh token
		let payload = {
			'grant_type': 'authorization_code',
			'code': authorizationGrantCode,
			'client_id': process.env.VUE_APP_API_CLIENT_ID
		};

		try {
			//console.log( 'exchangeAuthorizationGrantCode - exchange refresh token' );
			let apiResponse = await axios.post( process.env.VUE_APP_API_URL + 'auth/authorize', payload );
			commit( 'setDataFromToken', apiResponse.data.access_token );
			commit( 'setRefreshToken', apiResponse.data.refresh_token );
			console.log( 'go to dashboard' );
			await router.push( {name: 'dashboard'} )
		}
		catch( e ) {
			//console.log( 'exchangeAuthorizationGrantCode - error exchanging refresh token: ' + e.message );
			//console.log( e );
			await router.push( {
				name: 'auth.error',
				query: {
					code: e.code,
					message: e.message
				}
			} )
		}

		//console.log( 'exchangeAuthorizationGrantCode - end' );
	},

	hasRole:async function ( {state}, roleToCheck ) {
		return state.roles.includes(roleToCheck)
	}


}

const mutations = {

	setDataFromToken: function ( state, accessToken ) {
		try {
			let decodedAccessToken = jwt_decode( accessToken );
			state.accessToken = accessToken;
			state.roles = decodedAccessToken.scope;
			state.user = decodedAccessToken.data;
			state.isSignedIn = true;
			state.fresh = true;
		}
		catch( e ) {
			throw new AuthError( 'Invalid authentication token provided by API', 500, e, 'DDA5CE54-D6DB-4753-85FF-B947ED042ACE' );
		}
	},
	setAccessToken: function ( state, accessToken ) {
		state.accessToken = accessToken;
	},
	setRefreshToken: function ( state, refreshToken ) {
		state.refreshToken = refreshToken;
	},

	setModuleStateFromLocalStorage: function ( state ) {
		console.log( storeKey + ' setModuleStateFromLocalStorage' )
		if( localStorage.getItem( storeKey ) ) {
			// Replace the state object with the stored item
			let data = JSON.parse( localStorage.getItem( storeKey ) );
			if( data !== null ) {
				Object.assign( state, data )
				state.fresh = false;
				state.working = true;
			}
		}
	},

	setWorking: function ( state, val ) {
		state.working = val;
	},

	setGettingAccessToken: function ( state, val ) {
		state.gettingAccessToken = val;
	},

	setFresh: function ( state, val ) {
		state.fresh = val;
	},

	reset( state ) {
		Object.assign( state, getDefaultState() )
	},

}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
