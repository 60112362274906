import Vue       from 'vue'
import Vuex      from 'vuex'
import userStore from './user.store'

Vue.use( Vuex )


export default new Vuex.Store( {
	state:     {
		snackbars: []
	},
	getters:   {

		snackbars: function( state ) {
			return state.snackbars;
		},

		snackbarTimeout: function( state ) {
			if( typeof ( state.snackbars[ 0 ] )!='undefined' && typeof ( state.snackbars[ 0 ].timeout )=='number' ) {
				return state.snackbars[ 0 ].timeout;
			}
			return 4000;
		},
	},
	mutations: {

		sendSnackbar: function( state, snackbar ) {
			state.snackbars.push( snackbar );
		},

		removeSnackbar: function( state, id ) {
			let index = state.snackbars.findIndex( item => item.id===id );
			if( index!== -1 ) {
				state.snackbars.splice( index, 1 );
			}
		},

		setStateFromLocalStorage: function(/*state*/ ) {

		},


	},
	actions:   {

		initialize: async function( { commit } ) {
			await commit( 'setStateFromLocalStorage' );
		},

		resetAllStores: async function( { dispatch } ) {
			await Promise.all( [
				dispatch( 'user/reset', null, { root: true } ),
			] );
		},

		sendSnackbar: function( { commit }, payload ) {
			let snackbar = {
				id:      "snackbar_" + Math.random().toString( 36 ).substr( 2, 9 ),
				color:   payload.color,
				message: payload.message,
			};

			if( 'timeout' in payload ) {
				snackbar.timeout = payload.timeout;
			}

			commit( 'sendSnackbar', snackbar );

		},

		sendErrorSnackbar: function( { commit }, e ) {
			let message = '';
			if( e.code!==0 ) {
				message += e.code + ': ';
			}
			message += e.message;

			let snackbar = {
				id:      "snackbar_" + Math.random().toString( 36 ).substr( 2, 9 ),
				color:   'error',
				message: message,
				timeout: 5000
			};

			commit( 'sendSnackbar', snackbar );

		},

		removeSnackbar( { commit }, id ) {
			commit( 'removeSnackbar', id );
		},

	},
	modules:   {
		user:   userStore,
	}
} )
