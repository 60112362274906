import Vue from 'vue'
import VueRouter from 'vue-router'
import store     from '@/store/index.js'
import routes    from "@/router/routes";

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach( async ( to, from, next ) => {

	//VISUAL: START ROUTING LOADING

	//AUTHENTICATION GUARD
	if( to.meta.auth.required) {
		let userHasRequiredRoles = true;
		for(let i in to.meta.auth.roles) {
			let userHasRequiredRole = false;
			for(let j in store.state.user.roles) {
				if(to.meta.auth.roles[i]===store.state.user.roles[j]) {
					userHasRequiredRole = true;
				}
			}
			if(!userHasRequiredRole) {
				userHasRequiredRoles = false;
			}
		}

		if( !store.getters['user/isSignedIn'] || !userHasRequiredRoles ) {
			await store.dispatch('user/getAccessToken');
		}
	}

	next();

} );



// router.afterEach((/*to, from*/) => {
//
// 	//VISUAL: END ROUTING LOADING
// 	store.commit('route/routing', false);
//
// })

export default router
