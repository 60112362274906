export const ROLE_USER_READ        = 'User.Read';
export const ROLE_USER_WRITE       = 'User.Write';
export const ROLE_QUEUE_READ       = 'queue.read';
export const ROLE_QUEUE_WRITE      = 'queue.write';
export const ROLE_CONTACT_READ     = 'contact.read';
export const ROLE_CONTACT_WRITE    = 'contact.write';
export const ROLE_CHANNEL_READ     = 'channel.read';
export const ROLE_CHANNEL_WRITE    = 'channel.write';
export const ROLE_DEPARTMENT_READ  = 'department.read';
export const ROLE_DEPARTMENT_WRITE = 'department.write';
export const ROLE_MESSAGE          = 'message';
export const ROLE_SUBSCRIBE        = 'subscribe';
export const ADMIN_ROLES           = [
	ROLE_QUEUE_READ,
	ROLE_QUEUE_WRITE,
	ROLE_CONTACT_READ,
	ROLE_CONTACT_WRITE,
	ROLE_CHANNEL_READ,
	ROLE_CHANNEL_WRITE,
	ROLE_MESSAGE,
	ROLE_SUBSCRIBE,
	ROLE_USER_READ,
	ROLE_USER_WRITE,
	ROLE_DEPARTMENT_READ,
	ROLE_DEPARTMENT_WRITE,
];

export const ALL_ROLES = [
	ROLE_QUEUE_READ,
	ROLE_QUEUE_WRITE,
	ROLE_CONTACT_READ,
	ROLE_CONTACT_WRITE,
	ROLE_CHANNEL_READ,
	ROLE_CHANNEL_WRITE,
	ROLE_MESSAGE,
	ROLE_SUBSCRIBE,
	ROLE_USER_READ,
	ROLE_USER_WRITE,
	ROLE_DEPARTMENT_READ,
	ROLE_DEPARTMENT_WRITE,
];

export const waitUntil = ( condition ) => {
	return new Promise( ( resolve ) => {
		let interval = setInterval( () => {
			if( !condition() ) {
				return
			}

			clearInterval( interval )
			resolve()
		}, 100 )
	} )
}
