import * as constants from "@/constants"

const routes = [

	{
		path: '/',
		name: 'dashboard',
		component: () => import(/* webpackChunkName: "core" */ '@/views/HomeView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: []
			}
		}
	},
	{
		path: '/users',
		name: 'users',
		component: () => import(/* webpackChunkName: "users" */ '@/views/user/UserListView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_USER_READ
				]
			}
		}
	},
	{
		path: '/users/:id',
		name: 'user',
		component: () => import(/* webpackChunkName: "users" */ '@/views/user/UserView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_USER_WRITE
				]
			}
		}
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/ContactListView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_CONTACT_READ
				]
			}
		}
	},
	{
		path: '/contacts/:id',
		name: 'contact',
		component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/ContactView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_CONTACT_WRITE
				]
			}
		}
	},
	{
		path: '/departments',
		name: 'departments',
		component: () => import(/* webpackChunkName: "department" */ '@/views/department/DepartmentListView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_DEPARTMENT_READ
				]
			}
		}
	},
	{
		path: '/departments/:id',
		name: 'department',
		component: () => import(/* webpackChunkName: "department" */ '@/views/department/DepartmentView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_DEPARTMENT_WRITE
				]
			}
		}
	},
	{
		path: '/channels',
		name: 'channels',
		component: () => import(/* webpackChunkName: "channel" */ '@/views/channel/ChannelListView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_DEPARTMENT_READ
				]
			}
		}
	},
	{
		path: '/channels/:id',
		name: 'channel',
		component: () => import(/* webpackChunkName: "channel" */ '@/views/channel/ChannelView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_DEPARTMENT_WRITE
				]
			}
		}
	},
	{
		path: '/message',
		name: 'message',
		component: () => import(/* webpackChunkName: "message" */ '@/views/MessageView.vue'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_MESSAGE
				]
			}
		}
	},
	{
		path: '/queue',
		name: 'queue',
		component: () => import(/* webpackChunkName: "queue" */ '@/views/queue/QueueListView'),
		props: true,
		meta: {
			auth: {
				required: true,
				roles: [
					constants.ROLE_QUEUE_READ
				]
			}
		}
	},
	{
		path: '/unsubscribe',
		name: 'unsubscribe',
		component: () => import(/* webpackChunkName: "unsubscribe" */ '@/views/PublicUnsubscribeView.vue'),
		props: ( route ) => ( {
			username: route.query.username
		} ),
		meta: {
			auth: {
				required: false,
				roles: []
			}
		}
	},

	//AUTH
	{
		path: '/auth/sign-in',
		name: 'auth.signIn',
		component: () => import(/* webpackChunkName: "core" */ '@/views/auth/AuthSignIn'),
		props: ( route ) => ( {
			code: route.query.code,
			state: route.query.state,
			errorMessage: route.query.errorMessage,
		} ),
		meta: {
			auth: {
				required: false,
				roles: []
			}
		}
	},
	{
		path: '/auth/sign-out',
		name: 'auth.signOut',
		component: () => import(/* webpackChunkName: "auth.signOut" */ '@/views/auth/AuthSignOut'),
		props: true,
		meta: {
			auth: {
				required: false,
				roles: []
			}
		}
	},
	{
		path: '/auth/error',
		name: 'auth.error',
		component: () => import(/* webpackChunkName: "auth.error" */ '@/views/auth/AuthError'),
		props: ( route ) => ( {
			code: route.query.code,
			message: route.query.message
		} ),
		meta: {
			auth: {
				required: false,
				roles: []
			}
		}
	},


	{
		path: '*',
		name: 'error',
		component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorPage.vue'),
		meta: {
			auth: {
				required: false,
				roles: []
			}
		}
	}

]

export default routes;
