import { mdiGauge, mdiAccount, mdiHomeGroup, mdiFormatListChecks, mdiSend  } from "@mdi/js"

const navigation = [
	{
		name:'dashboard',
		params:{},
		text:'Dashboard',
		mdiIcon:mdiGauge
	},
	{
		name:'message',
		params:{},
		text:'Send Message',
		mdiIcon:mdiSend
	},
	{
		name:'contacts',
		params:{},
		text:'Contacts',
		mdiIcon:mdiAccount
	},
	{
		name:'channels',
		params:{},
		text:'Channels',
		mdiIcon:mdiFormatListChecks
	},
	{
		name:'departments',
		params:{},
		text:'Departments',
		mdiIcon:mdiHomeGroup
	},
	{
		name:'queue',
		params:{},
		text:'Queue',
		mdiIcon:mdiSend
	},
	{
		name:'users',
		params:{},
		text:'Users',
		mdiIcon:mdiAccount
	},
];

export default navigation;
