import Vue                  from 'vue'
import App                  from './App.vue'
import router               from './router'
import store                from './store'
import vuetify              from './plugins/vuetify'
import VuetifySnackbarQueue from 'vuetify-snackbar-queue'
import VueMeta              from "vue-meta";
import stateMixin           from './plugins/stateMixin'
import formattersMixin      from './plugins/formattersMixin'
import * as constants       from "@/constants"

import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.config.productionTip = false

//application level mixins and plugins
Vue.mixin( stateMixin );
Vue.mixin( formattersMixin );

Vue.prototype.$constants = constants;

//third party plugins/vue resources
Vue.use( VueMeta );
Vue.use( VuetifySnackbarQueue )
Vue.use( CKEditor )


// Subscribe to store updates
let initStore = async () => {
	await store.dispatch( 'initialize' );
	await store.dispatch( 'user/initialize' );

	store.subscribe( ( mutation, state ) => {
		// Store parts of the state
		if( mutation.type.startsWith( 'user' ) ) {
			localStorage.setItem( 'bulkemail.store.user', JSON.stringify( state.user ) );
		}
	} );
};
initStore().then( () => {

	//application
	new Vue( {
		router,
		store,
		vuetify,
		render: h => h( App )
	} ).$mount( '#app' )

} );
