<template>
	<v-app>

		<v-app-bar app clipped-left dark elevation="1" height="56" class="d-print-none" v-if="$route.name!=='unsubscribe'">
			<v-app-bar-nav-icon class="d-md-none" @click="drawer=!drawer">
				<v-icon>{{ icons.mdiMenu }}</v-icon>
			</v-app-bar-nav-icon>

			<v-toolbar-title class="mr-4">Bulk Email</v-toolbar-title>

			<template v-for="path in navigation">
				<v-btn :key="path.name+'-navbtn'"
				       v-if="!path.children && userCanSeeNavigationItem(path.name)"
				       :to="{name:path.name, params:path.params}"
				       exact
				       text
				       class="hidden-sm-and-down">
					{{ path.text }}
				</v-btn>
				<v-menu v-if="path.children && userCanSeeNavigationItem(path.name)"
				        :key="path.name+'navmenu'"
				        class="hidden-sm-and-down"
				        offset-y
				        open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" class="hidden-sm-and-down" exact text>
							{{ path.text }}
							<v-icon right>{{ mdiChevronDown }}</v-icon>
						</v-btn>
					</template>
					<v-list dense>
						<template v-for="childPath in path.children">
							<div v-if="childPath.children" :key="childPath.text+'-navchildsubheader'">
								<v-divider></v-divider>
								<v-subheader>
									{{ childPath.text }}
								</v-subheader>
								<v-list-item v-for="grandchildPath in childPath.children"
								             :key="grandchildPath.name+'-navgrandchildbtn'"
								             :to="{ name:grandchildPath.name, params:grandchildPath.params }"
								             exact>
									<v-list-item-icon>
										<v-icon small>{{ grandchildPath.mdiIcon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{ grandchildPath.text }}</v-list-item-title>
									<v-list-item-action></v-list-item-action>
								</v-list-item>
								<v-divider></v-divider>
							</div>
							<v-list-item v-if="!childPath.children && userCanSeeNavigationItem(childPath.name)"
							             :key="childPath.name+'-navchildbtn'"
							             :to="{ name:childPath.name, params:childPath.params }"
							             exact>
								<v-list-item-icon>
									<v-icon small>{{ childPath.mdiIcon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-title>{{ childPath.text }}</v-list-item-title>
								<v-list-item-action></v-list-item-action>
							</v-list-item>
						</template>

					</v-list>
				</v-menu>
			</template>

			<v-spacer></v-spacer>


			<v-divider vertical class="ml-2 mr-4"></v-divider>

			<div v-if="isSignedIn && $store.state.user.user.name" class="mr-2 text-caption hidden-sm-and-down">
				{{ $store.state.user.user.name }}
			</div>


			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" icon>
						<v-icon>{{ icons.mdiDotsVertical }}</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-subheader v-if="isSignedIn && $store.state.user.user.name">
						{{ $store.state.user.user.name }}
					</v-subheader>

					<v-list-item :to="{'name':'auth.signOut'}">
						<v-list-item-content>
							<v-list-item-title>Sign Out</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon small>{{ icons.mdiLogout }}</v-icon>
						</v-list-item-icon>
					</v-list-item>

					<v-divider></v-divider>

					<v-list-item href="https://apps.garrettcounty.org/portal">
						<v-list-item-content>
							<v-list-item-title>App Portal</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon small>{{ icons.mdiCube }}</v-icon>
						</v-list-item-icon>
					</v-list-item>

					<v-divider></v-divider>

					<v-list-item class="no-hover-active">
						<v-list-item-content class="text-caption">
							App: {{ appVersion }}<br>
							API: {{ apiVersion }}
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>

		</v-app-bar>

		<v-navigation-drawer v-model="drawer" clipped fixed left temporary width="300" class="d-print-none"  v-if="$route.name!=='unsubscribe'">
			<v-list nav>
				<template v-for="path in navigation">
					<v-list-item v-if="!path.children"
					             :key="path.name+'-navdrw-btn'"
					             :exact="true"
					             :to="{ name:path.name, params:path.params }"
					             link>
						<v-list-item-icon>
							<v-icon>{{ path.mdiIcon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ path.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-group v-else :key="path.name+'-navdrw-grp'" :value="false">
						<template v-slot:activator>
							<v-list-item-icon>
								<v-icon>{{ path.mdiIcon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ path.text }}</v-list-item-title>
							</v-list-item-content>
						</template>

						<template v-for="childPath in path.children">
							<div v-if="childPath.children" :key="childPath.name+'-navdrw-child-subheader'">
								<v-divider></v-divider>
								<v-subheader>
									{{ childPath.text }}
								</v-subheader>
								<v-list-item v-for="grandchildPath in childPath.children"
								             :key="grandchildPath.name+'-navdrw-grandchild-btn'"
								             :to="{ name:grandchildPath.name, path:grandchildPath.params }"
								             exact>
									<v-list-item-icon>
										<v-icon small>{{ grandchildPath.mdiIcon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{ grandchildPath.text }}</v-list-item-title>
									<v-list-item-action></v-list-item-action>
								</v-list-item>
								<v-divider></v-divider>
							</div>
							<v-list-item v-if="!childPath.children"
							             :key="childPath.name+'-navdrw-child-btn'"
							             :exact="true"
							             :to="{ name:childPath.name }"
							             link>
								<v-list-item-icon>
									<v-icon small>{{ childPath.mdiIcon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ childPath.text }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list-group>
				</template>

			</v-list>
		</v-navigation-drawer>


		<v-snackbar-queue :items="$store.getters.snackbars" :timeout="$store.getters.snackbarTimeout" center
		                  :close-button-icon="icons.mdiClose" top
		                  @remove="$store.dispatch('removeSnackbar', $event)"></v-snackbar-queue>

		<router-view />
	</v-app>
</template>

<script>
import {mdiLogout, mdiCube, mdiDotsVertical, mdiSnowman, mdiClose, mdiChevronDown, mdiMenu } from "@mdi/js";
import navigation  from "@/router/navigation.js";

export default {
	name: 'App',
	metaInfo() {
		return {
			title: 'Bulk Email'
		}
	},
	data:function(){
		return {
			drawer:false
		}
	},
	computed: {
		icons:      function() {
			return {
				mdiCube, mdiLogout, mdiClose, mdiChevronDown, mdiMenu,
				mdiDotsVertical: ( new Date() ).getMonth()==11 && ( new Date() ).getDate()>=20 ? mdiSnowman : mdiDotsVertical
			}
		},
		isSignedIn: function() {
			return this.$store.getters[ 'user/isSignedIn' ];
		},
		navigation: function() {
			return navigation;
		},
		appVersion: function() {
			return process.env.VUE_APP_APP_VERSION;
		},
		apiVersion: function() {
			return process.env.VUE_APP_API_VERSION;
		}
	},
	methods:  {
		//
		// userHasRole: function( role ) {
		// 	return this.$store.state["user/roles"].includes( role )
		// },

		findRoute: function( routeName, routes ) {
			for( let i = 0; i<routes.length; i++ ) {
				if( routes[ i ].name===routeName ) {
					return routes[ i ];
				}

				if( routes[ i ]?.children?.length ) {
					let childResult = this.findRoute( routeName, routes );
					if( childResult!==null ) {
						return childResult;
					}
				}
			}

			return null;
		},

		userCanSeeNavigationItem: function( routeName ) {
			let route = this.findRoute( routeName, this.$router.getRoutes() );
			if( route!==null ) {
				//user not signed in
				if( route.meta.auth.required && !this.$store.getters['user/isSignedIn'] ) {
					return false;
				}

				//user has the needed roles
				return route.meta.auth.roles.every( ( v ) => {
					return this.$store.state.user.roles.includes( v )
				} );
			}
			return false;
		}
	}
};
</script>

<style lang="scss">
// make vuetify dialogs movable
.v-dialog.v-dialog--active .popup-header, .v-dialog.v-dialog--active .v-card__title {
	cursor: grab;
}

.v-dialog.v-dialog--active .popup-header:active, .v-dialog.v-dialog--active .v-card__title:active {
	cursor: grabbing;
}

//end make vuetify dialogs movable
</style>
